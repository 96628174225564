<template>
  <div>
    <!-- Title -->
    <b-card-title class="text-primary mb-3">Create Password</b-card-title>

    <register
      v-if="mobile"
      :mobile="mobile"
      @success="successRegisterRequest"
    />
  </div>
</template>

<script>
import Register from "../../components/auth/Register.vue";

export default {
  components: { Register },
  created() {
    if (!this.mobile) {
      // no mobile no. found in route params
      this.$router.replace({ name: "Home" });
      return;
    }
  },
  data() {
    return {
      query: this.$route.query,
      routeParams: this.$route.params,
    };
  },
  computed: {
    mobile() {
      return this.routeParams.mobile;
    },
  },
  methods: {
    successRegisterRequest(data) {
      // redirect to verify OTP
      this.$router.push({
        name: "AuthVerifyOtp",
        params: { otpData: { ...data } },
        query: this.query,
      });
    },
  },
};
</script>
